<template>
    <div class="box my-account">
        <div class="notice-box">
            <div class="notice-info">
                <div class="notice-details">
                    提交成功，请耐心等待工作人员核对
                </div>
                <p>恭喜，您刚刚的操作成功啦！</p>
                <div class="text-brand mb10 f14">
                    确认汇款后平台将在2个工作日内审核。如有疑问请及时联系客服咨询，客服电话: <span v-if="siteInfo.web_phone">{{ siteInfo.web_phone }}</span>
                </div>
                <div class="notice-actions">
                    <el-button size="small" icon="el-icon-s-home" @click="goHome" type="primary" round>回到首页</el-button>
                    <el-button size="small" icon="el-icon-arrow-left" @click="goList" round>继续操作</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        name: "subSuccess",
        data() {
            return {}
        },
        props: {},
        mixins: [],
        watch: {},
        computed: {},
        computed: {
            ...mapGetters(["siteInfo"])
        },
        created() {
        },
        mounted() {

        },
        methods: {
            goHome() {
                this.$router.push('/')
            },
            goList() {
                this.$router.push('/member/order_list')
            },
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .my-account {
    background: #ffffff;
    padding: 50px 20px;
    .notice-box{
      height: auto;
      display: table;
      padding: 150px 0;
      width: 800px;
      margin: 0 auto;
      .notice-info{
        width: 100%;
        height: auto;
        display: table;
        padding-bottom: 10px;
        padding-left: 200px;
        background: url(https://mktest.hn-gdjt.com/pc_shop/img/success.355b7108.svg) no-repeat 0 0;
        background-size: auto 100%;
        p{
          width: 100%;
          display: block;
          font-size: 14px;
          color: #999;
          margin-bottom: 10px;
        }
        .text-brand{
          color: #d80000;
          width: 600px;
        }
      }
      .notice-details{
        width: 100%;
        height: 32px;
        font-size: 20px;
        font-weight: 700;
        color: #11c26d;
      }
    }
  }
</style>
